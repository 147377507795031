import background from './christin-hume-mfB1B1s4sMc-unsplash.jpg'
import './App.css';
import { Button, TextField, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CheckCircle, Clear } from '@mui/icons-material';

const DEFAULT_REQUESTS = 128;
const DEFAULT_DOMAIN = 'smallish-moldy-apple-nylxa9.tk';
const DEFAULT_PATH = 'pixel.gif';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  const [domain, setDomain] = useState(DEFAULT_DOMAIN);
  const [numRequests, setNumRequests] = useState(DEFAULT_REQUESTS);
  const [tunnels, setTunnels] = useState([]);

  const handleClick = useCallback(async () => {
    let requests = Math.floor( (numRequests || DEFAULT_REQUESTS) / 8 )
    if (requests > 256) requests = 256;
    let d = domain || DEFAULT_DOMAIN;
    let scheme = "http://"
    if (window.location.href.startsWith("https://")) scheme = "https://"
    let tunnelsReq = [];
    for (let i = 0; i < requests; i++) {
      const hex = [...Array(16)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
      const url = `${scheme}${hex}.${d}/${DEFAULT_PATH}`;
      const response = await fetch(url);
      tunnelsReq.push({url: `${hex}.${d}`, success: !!response.ok});
    }
    setTunnels(tunnelsReq);
  }, [domain, numRequests, tunnels]);

  return (
    <ThemeProvider theme={darkTheme}>
    <div className="App">
      <header 
        className="App-header" 
        style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover' }}>
        <div style={{backgroundColor: 'rgba(0,0,0,0.75)', padding: '2rem', borderRadius: '0.5rem'}}>
          <Typography variant="h4">
            Test Cloudflare DNS tunneling protection
          </Typography>
          <br/>
          <div style={{padding: '0.5rem', borderRadius: '2px'}}>
            {/* <TextField 
              label="Domain" 
              value={domain} 
              onChange={(e) => setDomain(e.target.value)} 
            />
            &nbsp; */}
            <TextField 
              label="Bytes to transmit"
              value={numRequests} 
              onChange={(e) => setNumRequests(e.target.value)} 
            />
          </div>
          <br/>
          <Button 
            size="large" 
            variant="contained" 
            onClick={handleClick}
          >
              Send Data
          </Button>
        </div>
        {tunnels.length > 0 && (
          <div style={{marginTop: '1rem', backgroundColor: 'rgba(0,0,0,0.75)', padding: '2rem', borderRadius: '0.5rem'}}>
            {tunnels.map((tunnel) => (
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                  <Typography>{tunnel.url}</Typography>
                </div>
                <div>
                  {tunnel.success && <CheckCircle color="success" />}
                  {!tunnel.success && <Clear color="error" />}
                </div>
              </div>
            ))}
          </div>
        )}
      </header>
    </div>
    </ThemeProvider>
  );
}

export default App;
